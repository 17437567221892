import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import CTAButton from './CTAButton';

import { useHealthAssessmentCtx } from '../contexts';

import './LocationFinderCard.sass';

export default ({
  locationId,
  locationName,
  locationLineOne,
  locationLineTwo,
  locationTownCity,
  locationPostcode,
  locationText,
  distance,
}) => {
  const history = useHistory();
  const { haCtxActions, state } = useHealthAssessmentCtx();

  // Store data, call API - GET Slots & direct user to next page
  const selectLocation = useCallback(() => {
    // Store selected Health Assessment location to state
    haCtxActions.addLocation({
      location: {
        locationId,
        locationName,
        locationLineOne,
        locationLineTwo,
        locationTownCity,
        locationPostcode,
        locationText,
      },
    });
    // Direct user to next page
    history.push('/healthAssessmentSlots/');
  }, []);

  return (
    <div className="location-finder-card-container" id={locationId}>
      <div className="top">
        <h3 className="location">{locationName}</h3>
        <p className="distance">{`${distance} miles`}</p>
      </div>
      <div className="bottom">
        <p className="address">
          {locationLineOne && (
            <>
              <span>{locationLineOne}</span>
              <br />
            </>
          )}
          {locationLineTwo && (
            <>
              <span>{locationLineTwo}</span>
              <br />
            </>
          )}
          {locationTownCity && (
            <>
              <span>{locationTownCity}</span>
              <br />
            </>
          )}
          {locationPostcode && <span>{locationPostcode}</span>}
        </p>
        <CTAButton
          action={{
            call: state ? () => selectLocation : null,
            type: 'SELECT_LOCATION',
            name: 'Select location',
          }}
          isNotAsync
          onAction={selectLocation}
        />
      </div>
    </div>
  );
};
